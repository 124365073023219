import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import ContactInfoBox from "./ContactInfoBox";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaStarOfLife } from "react-icons/fa6";

interface ContactFormType {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const Contact = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [contactForm, setContactForm] = useState<ContactFormType>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const [ref1, inView1] = useInView({ threshold: 0.1, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.1, triggerOnce: true });

  React.useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);

  React.useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    }
  }, [controls2, inView2]);

  const parentVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 0.5 },
    },
  };

  const bottomRef = useRef(null);
  const [isBottomVisible, setIsBottomVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBottomVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [bottomRef]);

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContactForm((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const submitContact = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/contactus`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactForm),
      }
    );

    const data = await response.json();

    if (data.status === 1) {
      toastFun("Error", data.message, "error");
      return setIsLoading(false);
    } else if (data.status === 0) {
      toastFun("Success", data.message, "success");
      setContactForm({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
    setIsLoading(false);
    window.location.reload();
  };

  return (
    <div className="min-h-[100vh] mt-10">
      <div className=" flex justify-center mb-10">
        <h1 className=" text-center self-center text-[23px] lg:text-[30px] font-bold lg:mt-8">
          <span className="text-[#0B4D39] text-[32px]">Contact Us</span>
          <Divider
            orientation="horizontal"
            sx={{
              borderColor: "#123456", // Replace with your custom color
              borderBottom: "4px",
              width: "30%",
              mx: "auto",
              mt: 1,
            }}
          />
        </h1>
      </div>

      <motion.div
        ref={ref1}
        animate={controls1}
        initial="hidden"
        variants={parentVariants}
        className="block lg:flex justify-center bg-[#0B4D39] lg:py-20 lg:rounded-t-[2%] componentShadow"
      >
        <div className="w-[80%] lg:w-[30%] mx-auto">
          <h1 className="text-[23px] text-center mb-3 font-semibold text-[#CDCDCD] pt-5 lg:pt-0">
            Contact Form
          </h1>
          <form onSubmit={submitContact}>
            <FormControl
              mb={3}
              className="w-[100%]"
              onSubmit={(e) => submitContact(e)}
            >
              <FormLabel color={"#CDCDCD"} htmlFor="name">
                <div className=" flex">
                  Name{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                required
                borderColor={"#CDCDCD"}
                _hover={{ borderColor: "#CDCDCD" }}
                placeholder="Name"
                size="md"
                color={"#CDCDCD"}
                mb={"0.8rem"}
                _placeholder={{ color: "grey" }}
                name="name"
                onChange={handleInputChange}
                id="name"
              />
              <FormLabel color={"#CDCDCD"} htmlFor="email">
                <div className=" flex">
                  Email{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                required
                borderColor={"#CDCDCD"}
                _hover={{ borderColor: "#CDCDCD" }}
                placeholder="Email"
                size="md"
                color={"#CDCDCD"}
                mb={"0.8rem"}
                _placeholder={{ color: "grey" }}
                name="email"
                type="email"
                onChange={handleInputChange}
                id="email"
              />
              <FormLabel color={"#CDCDCD"} htmlFor="phone">
                <div className=" flex">
                  Phone{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                required
                borderColor={"#CDCDCD"}
                _hover={{ borderColor: "#CDCDCD" }}
                placeholder="09123456789"
                size="md"
                color={"#CDCDCD"}
                mb={"0.8rem"}
                _placeholder={{ color: "grey" }}
                name="phone"
                onChange={handleInputChange}
                id="phone"
              />
              <FormLabel color={"#CDCDCD"} htmlFor="message">
                <div className=" flex">
                  Message{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Textarea
                required
                borderColor={"#CDCDCD"}
                _hover={{ borderColor: "#CDCDCD" }}
                placeholder="Message"
                size="md"
                color={"#CDCDCD"}
                mb={"0.8rem"}
                _placeholder={{ color: "grey" }}
                name="message"
                onChange={handleInputChange}
                id="message"
                rows={6}
              />
              <div className="flex justify-end">
                <Button
                  bgColor={"#75A595"}
                  isLoading={isLoading}
                  _hover={{ bgColor: "#9AC4B6", color: "#000" }}
                  transition={"all"}
                  transitionDuration={"400ms"}
                  type="submit"
                  px={5}
                >
                  Submit
                </Button>
              </div>
            </FormControl>
          </form>
        </div>
        <Divider
          height="auto"
          borderColor="#CDCDCD"
          borderWidth="2px"
          orientation="vertical"
          className="hidden lg:flex"
        />
        <Divider
          height="auto"
          borderColor="#CDCDCD"
          borderWidth="2px"
          orientation="horizontal"
          className="block lg:hidden my-8"
        />
        <div className="w-[80%] lg:w-[30%] mx-auto">
          <h1 className="text-[23px] text-center mb-3 font-semibold text-[#CDCDCD]">
            Contact Info
          </h1>
          <ContactInfoBox />
        </div>
      </motion.div>
      <div>
        <iframe
          className="w-[100%] h-[550px]"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d234.72068205475634!2d96.14894220501903!3d19.732585602106912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2smm!4v1711792332849!5m2!1sen!2smm"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div ref={bottomRef} />
      </div>
    </div>
  );
};

export default Contact;
