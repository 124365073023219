import React from "react";
import Layout from "../layout/Layout";
import { motion } from "framer-motion";
import CustomSlider from "../Components/Custom/CustomSlider";
import { Divider } from "@chakra-ui/react";

const Home = () => {
  return (
    <div className=" h-[24vh] w-full md:h-[40vh] lg:h-auto dark:bg-[#373737]">
      <div className="">
        <CustomSlider />
      </div>
    </div>
  );
};

export default Home;

// Royal Smart Tech Myanmar
