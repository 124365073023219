import React, { useEffect, useRef, useState } from "react";
import Layout from "../../layout/Layout";
import AdminNavbar from "../bars/AdminNavBar";
import { getToken } from "../../lib/auth";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setDeleteLoading,
  setFetchLoading,
  setTotal_count,
} from "../../store/slices/globalSlice";
import usePagination from "../../hooks/usePagination";
import CustomTable from "../Custom/CustomTable";
import Pagination from "../Custom/CustomPaginate";
import { setGalleryDatas } from "../../store/slices/gallerySlice";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Stack,
  Text,
  Image,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomModal from "../Custom/CustomModal";
import GalleryEditModal, {
  EditGalleryModalRef,
} from "./Modal/GalleryEditModal";
import {
  GalleryDataType,
  GalleryManagementType,
} from "../../types/GalleryDataType";
import GalleryCreateModal, {
  CreateGalleryModalRef,
} from "./Modal/GalleryCreateModal";
import AddPhotosModal, { AddPhotosModalRef } from "./Modal/AddPhotosModal";
import TruncatedText from "../Custom/TruncatedText";
import { Link } from "react-router-dom";
import Loading from "../Custom/Loading";

const Gallery = () => {
  const access_token = getToken();
  // console.log("access_token :: ", access_token);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isFetchLoading, createLoading } = useAppSelector(
    (state) => state.global
  );
  const { data } = useAppSelector((state) => state.gallery);
  const { total_count } = useAppSelector((state) => state.global);

  const dispatch = useAppDispatch();
  const { limit, onPaginationChange, skip, pagination } = usePagination();
  const [currentPage, setCurrentPage] = useState(0);
  const [galleryAlbumDataForDelete, setGalleryAlbumsDataForDelete] = useState<
    string | null
  >(null);

  const toast = useToast();
  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const galleryCreateModalRef = useRef<CreateGalleryModalRef>(null);

  const handleCreateModal = () => {
    if (galleryCreateModalRef.current) {
      galleryCreateModalRef.current.open();
    }
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const galleryEditModalRef = useRef<EditGalleryModalRef>(null);

  const handleEditModal = (gallery: GalleryDataType) => {
    // console.log("gallery :: ", gallery);
    if (galleryEditModalRef.current) {
      galleryEditModalRef.current.open({ ...gallery });
    }
  };

  const addPhotosModalRef = useRef<AddPhotosModalRef>(null);

  const addPhotosModalFun = (id: string) => {
    if (addPhotosModalRef.current) {
      addPhotosModalRef.current.open(id);
    }
  };

  useEffect(() => {
    getGalleries();
  }, [currentPage]);

  const getGalleries = async () => {
    dispatch(setFetchLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/gallery?page=${
        currentPage + 1
      }&limit=8`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    const data = await response.json();
    dispatch(setTotal_count(data.totalPages));
    dispatch(setGalleryDatas(data.galleries));
    dispatch(setFetchLoading(false));
  };

  const deleteComfirmFun = async () => {
    dispatch(setDeleteLoading(true));
    if (galleryAlbumDataForDelete) {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/gallery/${galleryAlbumDataForDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const data = await response.json();

      // console.log("response :: ", data.message);
      toastFun("Success", data.message, "success");
      onClose();

      dispatch(setDeleteLoading(false));
      getGalleries();
    }
  };

  return (
    <Layout>
      <div>
        <Text
          fontSize={"30px"}
          fontWeight={"bold"}
          // cursor={"pointer"}
          className=" pt-20 md:pt-10 ml-3 md:ml-0"
        >
          Album
        </Text>
      </div>
      <div className=" flex justify-end">
        <Button
          variant="solid"
          colorScheme="blue"
          onClick={() => {
            handleCreateModal();
          }}
          className=" mr-3 md:mr-0 mb-4"
        >
          Add Album
        </Button>
      </div>
      {/* <div className=" mb-8">
        <Pagination pageCount={total_count} onPageChange={handlePageChange} />
      </div> */}
      {isFetchLoading ? (
        <div className=" h-[55vh] lg:h-[70vh]">
          <Loading />
        </div>
      ) : (
        <div className=" flex flex-wrap gap-6 justify-center pb-28 md:pb-24">
          {data?.map((item) => {
            return (
              <Card
                maxW="sm"
                key={item.id}
                transition="box-shadow 0.2s ease-in-out"
                _hover={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
                }}
              >
                <Link
                  to={`/admin_dashboard_panel/gallery/${item.id}`}
                  className=""
                >
                  <CardBody>
                    <Image
                      src={item.cv_photo}
                      alt="Gallery cover photo"
                      borderRadius="lg"
                      w={"450px"}
                      h={"270px"}
                      objectFit={"cover"}
                    />
                    <Stack mt="6" spacing="3" className="h-[180px]">
                      <Heading size="md">{item.name}</Heading>
                      {/* <Text
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></Text> */}
                      <TruncatedText text={item.description} id={item.id} />
                      {/* <Text>see more</Text> */}
                      <div className="mt-auto ml-auto">
                        <Link
                          to={`/admin_dashboard_panel/gallery/${item.id}`}
                          className=""
                        >
                          <Button variant={"solid"}>View More</Button>
                        </Link>
                      </div>
                    </Stack>
                  </CardBody>
                </Link>
                <Divider />
                <CardFooter>
                  <ButtonGroup spacing="2">
                    <Button
                      variant="solid"
                      colorScheme="blue"
                      onClick={() => {
                        handleEditModal(item);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="solid"
                      colorScheme="red"
                      onClick={() => {
                        setGalleryAlbumsDataForDelete(item.id);
                        onOpen();
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                  <Button
                    className=" ml-auto"
                    variant="solid"
                    bgColor={"#40B245"}
                    transition={"all"}
                    transitionDuration={"500ms"}
                    _hover={{
                      bgColor: "#004C33",
                      color: "white",
                    }}
                    onClick={() => addPhotosModalFun(item.id)}
                  >
                    Add Photos
                  </Button>
                </CardFooter>
              </Card>
            );
          })}
        </div>
      )}

      <div className=" w-[95vw] absolute bottom-10">
        <Pagination pageCount={total_count} onPageChange={handlePageChange} />
      </div>
      <CustomModal
        modalTitle={"Delete"}
        modalText={`Are you sure to Delete Gallery Album ?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        actionFun={deleteComfirmFun}
        actionText={"Delete"}
      />
      <GalleryEditModal
        title={"Edit Album"}
        ref={galleryEditModalRef}
        fetchData={getGalleries}
      />
      <GalleryCreateModal
        title={"Add A New Album"}
        fetchData={getGalleries}
        ref={galleryCreateModalRef}
      />
      <AddPhotosModal
        title={"Add Photos"}
        ref={addPhotosModalRef}
        fetchData={getGalleries}
      />
    </Layout>
  );
};

export default Gallery;
