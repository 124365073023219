import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { GalleryDataType } from "../../types/GalleryDataType";

interface GallerySliceState {
  isLoading: boolean;
  data: GalleryDataType[];
  error: Error | null;
}

const initialState: GallerySliceState = {
  isLoading: false,
  data: [],
  error: null,
};

export const gallerySlice = createSlice({
  name: "galleryData",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setGalleryDatas: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading, setGalleryDatas } = gallerySlice.actions;

export default gallerySlice.reducer;
