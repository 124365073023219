"use client";
import {
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Button,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import { CiMenuFries } from "react-icons/ci";
// import { useStore } from "../store/store";
// import CustomModal from "./custom/CustomModal";
// import { removeAuth } from "../lib/auth";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../Custom/CustomModal";
import { removeAuth } from "../../lib/auth";
import { FaEarthAmericas } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa";

interface Props {
  onShowSidebar: VoidFunction;
  showSidebarButton?: boolean;
}

const AdminNavbar = ({ showSidebarButton = true, onShowSidebar }: Props) => {
  // const { unreadCount } = useStore((state) => state);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const LogoutFun = () => {
    // console.log("logout:::");
    removeAuth();
    navigate("/admin_dashboard_panel/login");
  };

  return (
    <Flex
      bg={"#222C37"}
      p={4}
      color="white"
      alignItems="center"
      justifyContent="center"
      shadow="lg"
      position={"fixed"}
      w={"100%"}
      zIndex={30}
    >
      <Box flex="1">
        {showSidebarButton && (
          <div className=" flex">
            <CiMenuFries
              style={{ fontSize: "25px", color: "#fff" }}
              onClick={onShowSidebar}
              cursor={"pointer"}
            />
            {/* <Badge
              ml="1"
              fontSize="0.8em"
              colorScheme="green"
              position={"absolute"}
              top={2}
              left={9}
            >
              {unreadCount > 0 && unreadCount}
            </Badge> */}
            <h1 className=" ml-5 text-[20px] hidden md:block">
              Royal Smart-Tech Myanmar
            </h1>
            <h1 className=" ml-5 text-[20px] block md:hidden">R.S.T.M </h1>

            <Link to={"/"} className=" ml-5 text-white my-auto text-[23px]">
              <FaEarthAmericas />
            </Link>
          </div>
        )}
      </Box>
      <Box flex="end">
        <Menu id="menu-button-R5aqiucqH1">
          <MenuButton>
            <div className=" flex align-middle">
              <FaCaretDown className=" mr-2 my-auto" />
              <Avatar
                size="sm"
                name="Royal Smart Tech"
                src="/royal_smart_tech.png"
              />
            </div>
          </MenuButton>
          <MenuList color="#000">
            <Button
              width={"100%"}
              onClick={() => onOpen()}
              bg={"#b42727"}
              color={"white"}
              _hover={{
                bg: "#b42727",
              }}
            >
              Log Out
            </Button>
          </MenuList>
        </Menu>
      </Box>

      <CustomModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        modalTitle={"Log Out"}
        modalText={"Are you sure to logout??"}
        actionFun={LogoutFun}
        actionText={"Log Out"}
      />
    </Flex>
  );
};

export default AdminNavbar;
