import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../lib/auth";
import {
  Button,
  Card,
  CardBody,
  Image,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddPhotosModal, { AddPhotosModalRef } from "../Modal/AddPhotosModal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setPhotoDatas } from "../../../store/slices/photosSlice";
import { MdDeleteForever } from "react-icons/md";
import {
  setDeleteLoading,
  setFetchLoading,
  setTotal_count,
} from "../../../store/slices/globalSlice";
import CustomModal from "../../Custom/CustomModal";
import Pagination from "../../Custom/CustomPaginate";
import { TfiControlBackward } from "react-icons/tfi";
import Loading from "../../Custom/Loading";

const AddPhotosDetail = () => {
  const { id } = useParams<{ id: string }>();
  // console.log("id :: ", id);

  const access_token = getToken();

  const { photoData } = useAppSelector((state) => state.photo);
  const { total_count, isFetchLoading } = useAppSelector(
    (state) => state.global
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(0);

  const toast = useToast();
  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const fetchAllPhotos = async () => {
    dispatch(setFetchLoading(true));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/photos/${id}?page=${
          currentPage + 1
        }&limit=10`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (!response.ok) {
        dispatch(setFetchLoading(false));
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      dispatch(setPhotoDatas(data.photos));
      dispatch(setTotal_count(data.totalPages));
      dispatch(setFetchLoading(false));
    } catch (error) {
      dispatch(setFetchLoading(false));
      console.error("Fetching photos failed:", error);
    }
  };

  //   console.log("image data : : ", photoData);

  useEffect(() => {
    fetchAllPhotos();
  }, [currentPage]);

  const addPhotosModalRef = useRef<AddPhotosModalRef>(null);

  const addPhotosModalFun = (id: string) => {
    if (addPhotosModalRef.current) {
      addPhotosModalRef.current.open(id);
    }
  };

  const deleteComfirmFun = async (id: string) => {
    dispatch(setDeleteLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/photos/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    const data = await response.json();

    // console.log("response :: ", data.message);
    if (data.code === 200) {
      toastFun("Success", data.message, "success");
      onClose();
    }

    dispatch(setDeleteLoading(false));
    fetchAllPhotos();
  };

  return (
    <Layout>
      <div className="pt-20 md:pt-10">
        <div className=" flex mb-5 justify-between">
          <div className="flex justify-start">
            <Button
              className="mr-auto ml-2 md:ml-0"
              variant="solid"
              bgColor={"#40B245"}
              transition={"all"}
              transitionDuration={"500ms"}
              _hover={{
                bgColor: "#004C33",
                color: "white",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <TfiControlBackward /> <span className=" ml-2">Back</span>
            </Button>
            <AddPhotosModal
              title={"Add Photos"}
              ref={addPhotosModalRef}
              fetchData={fetchAllPhotos}
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="ml-auto mr-2 md:mr-2"
              variant="solid"
              bgColor={"#40B245"}
              transition={"all"}
              transitionDuration={"500ms"}
              _hover={{
                bgColor: "#004C33",
                color: "white",
              }}
              onClick={() => {
                if (id) addPhotosModalFun(id);
              }}
            >
              Add Photos
            </Button>
            <AddPhotosModal
              title={"Add Photos"}
              ref={addPhotosModalRef}
              fetchData={fetchAllPhotos}
            />
          </div>
        </div>

        {isFetchLoading ? (
          <div className=" h-[55vh] lg:h-[70vh]">
            <Loading />
          </div>
        ) : (
          <div className="flex flex-wrap gap-10 justify-center mb-16">
            {Array.isArray(photoData) && photoData.length > 0 ? (
              photoData.map((item) => (
                <Card
                  maxW="sm"
                  key={item.id}
                  maxH={"400px"}
                  className=" w-[100%]"
                >
                  <CardBody>
                    <Image
                      src={item.img_url}
                      alt="Photos"
                      borderRadius="lg"
                      objectFit={"cover"}
                      height={"350px"}
                      className=" w-[100%]"
                    />
                    <Button
                      size="sm"
                      colorScheme="red"
                      position="absolute"
                      top="0"
                      right="0"
                      p={0}
                      onClick={() => deleteComfirmFun(item.id)}
                    >
                      <MdDeleteForever className=" text-[20px]" />
                    </Button>
                  </CardBody>
                </Card>
              ))
            ) : (
              <p>No photos available.</p>
            )}
          </div>
        )}

        {/* <CustomModal
        modalTitle={"Delete"}
        modalText={`Are you sure to Delete Gallery Album ?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        actionFun={deleteComfirmFun(id as string)}
        actionText={"Delete"}
      /> */}
        <div className=" w-[95vw] absolute bottom-10">
          <Pagination pageCount={total_count} onPageChange={handlePageChange} />
        </div>
      </div>
    </Layout>
  );
};

export default AddPhotosDetail;
