import CryptoJS from "crypto-js";
import { config } from "../../config/config";
const hashTrigger = config.hashTrigger;
const AuthStoreName = "auth";

interface AcceptedEncrypted {
  value: any;
  storageName: string;
}

const _encryptedSave = ({ value, storageName }: AcceptedEncrypted) => {
  const cipherValue = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    hashTrigger
  ).toString();
  if (typeof window !== "undefined") {
    localStorage.setItem(storageName, cipherValue);
  }
};

const _decryptedExecute = (value: string) => {
  try {
    if (typeof window !== "undefined") {
      const data = localStorage.getItem(value);
      if (data === null || data === undefined) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(data, hashTrigger);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
  } catch (error) {
    removeAuth();
  }
};

const saveAuth = (data: any) => {
  const obj = {
    value: data,
    storageName: AuthStoreName,
  };
  _encryptedSave(obj);
  return data;
};

// const getToken = () => {
//   if (typeof window !== "undefined") {
//     return localStorage.getItem("access_token");
//   }
// };

const getAuth = () => {
  return _decryptedExecute(AuthStoreName);
};
const removeAuth = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(AuthStoreName);
    localStorage.removeItem("access_token");
  }
};

const saveToken = (token: string) => {
  const expirationTime = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
  const expiryDate = Date.now() + expirationTime;

  const obj = {
    token: token,
    expiryDate: expiryDate,
  };

  const cipherValue = CryptoJS.AES.encrypt(
    JSON.stringify(obj),
    hashTrigger
  ).toString();

  if (typeof window !== "undefined") {
    localStorage.setItem("access_token", cipherValue);
  }
};

const getToken = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const encryptedData = localStorage.getItem("access_token");
  if (!encryptedData) {
    return null;
  }

  const bytes = CryptoJS.AES.decrypt(encryptedData, hashTrigger);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  const { token, expiryDate } = decryptedData;
  if (Date.now() > expiryDate) {
    localStorage.removeItem("access_token");
    return null;
  }

  return token;
};

export { saveAuth, getAuth, getToken, removeAuth, saveToken };
