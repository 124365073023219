import { Suspense } from "react";
import Loading from "./Loading";

interface PropsType {
  children: React.ReactNode;
  fullScreen?: boolean;
}

const Wrapper = ({ children, fullScreen }: PropsType) => {
  return (
    <Suspense fallback={<Loading fullScreen={fullScreen} />}>
      {children}
    </Suspense>
  );
};

export default Wrapper;
