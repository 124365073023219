import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SliderComponent = () => {
  const slides = [
    {
      id: 1,
      title: "Slide 1",
      content: "This is the first slide content.",
      image: "./first-slider-img.png",
    },
    {
      id: 2,
      title: "Slide 2",
      content: "This is the second slide content.",
      image: "./second-slider-img.png",
    },
    {
      id: 3,
      title: "Slide 3",
      content: "This is the third slide content.",
      image: "./third-slider-img.png",
    },
  ];

  return (
    <div className="flex justify-center mt-16 lg:mt-16 w-[100%]">
      <Swiper
        pagination={{
          type: "bullets",
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
        {slides.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item.image}
              alt={`Image-${index + 1}`}
              className="w-full h-[23vh] md:h-[400px] lg:h-[450px] xl:h-auto 2xl:h-auto"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderComponent;
