import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Text,
  Box,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { Editor } from "@ckeditor/ckeditor5-core";
import { useAppSelector } from "../../../../store/hooks";
import { getToken } from "../../../../lib/auth";
import { ServiceDataType } from "../../../../types/ServiceDataType";
import { setEditLoading } from "../../../../store/slices/globalSlice";
import CustomCKEditor from "../../../Custom/CustomCKEditor";
import { FaStarOfLife } from "react-icons/fa6";

interface EditServiceModalProps {
  title: string; // Data to be edited
  fetchData: () => void;
}

export interface EditServiceModalRef {
  open: (data: ServiceDataType) => void; // Updated to accept data object
  close: (data: any) => void;
}

const ServiceEditModal: React.ForwardRefRenderFunction<
  EditServiceModalRef,
  EditServiceModalProps
> = ({ title, fetchData }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState<ServiceDataType>({
    id: "",
    name: "",
    description: "",
    base64Data: "",
    cv_photo: "",
  });
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const dispatch = useDispatch();
  const EditLoading = useAppSelector((state) => state.global.editLoading);
  const toast = useToast();
  const access_token = getToken();

  useImperativeHandle(ref, () => ({
    open: (data: ServiceDataType) => {
      onOpen();
      setFormData(data);
    },
    close: onClose,
  }));

  // console.log("formData :: ", formData);

  const formFillingFun = (type: string, value: string) => {
    setFormData({ ...formData, [type]: value });
  };

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, id: any) => {
    e.preventDefault();
    dispatch(setEditLoading(true));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/services/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify({
            name: formData.name,
            description: formData.description,
            base64Data: formData.base64Data,
          }),
        }
      );

      const data = await response.json();
      // console.log("formData.base64Data :: ", formData.base64Data);
      toastFun("Success", data.message, "success");
      dispatch(setEditLoading(false));
      setFormData({
        id: "",
        name: "",
        description: "",
        base64Data: "",
        cv_photo: "",
      });
      onClose();
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData: any) => ({
        ...prevData,
        base64Data: base64 as string,
      }));
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const convertToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveFile = () => {
    setFormData((prevData: any) => ({ ...prevData, base64Data: "" }));
    setSelectedImage(null);
  };

  // const handleSubmit = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   console.log(formData);
  //   // Handle form submission logic here
  // };

  const handleEditorChange = (event: string, editor: any) => {
    const data = editor.getData();
    setFormData({ ...formData, description: data });
  };

  // Render modal content
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{
          base: "100%",
          sm: "90%",
          md: "70%",
          lg: "60%",
          xl: "40%",
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={(e) => handleSubmit(e, formData.id)}>
            <FormControl>
              <FormLabel>
                <div className=" flex">
                  Name{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                mb="10px"
                bgColor={"neat.pearlwhite"}
                value={formData.name}
                fontWeight="500"
                size="lg"
                placeholder="Name"
                onChange={(e) => formFillingFun("name", e.target.value)}
              />
            </FormControl>

            <FormLabel className=" mt-3">
              <div className=" flex">
                Description{" "}
                <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
              </div>
            </FormLabel>
            <CustomCKEditor
              data={formData.description}
              handleChange={handleEditorChange}
            />

            <FormLabel className=" mt-3">
              <div className=" flex">
                Cover Photo{" "}
                <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
              </div>
            </FormLabel>
            {/* <Box>
              <Input type="file" onChange={handleFileChange}/>
              {selectedFileName && (
                <Box mt={2}>
                  <Text>Selected file: {selectedFileName}</Text>
                  <Button mt={2} size="sm" onClick={handleRemoveFile}>
                    Remove
                  </Button>
                </Box>
              )}
            </Box> */}

            <Input
              type="file"
              onChange={handleFileChange}
              display="none"
              id="file-upload"
              accept="image/*"
            />
            <label htmlFor="file-upload">
              <Box
                as="div"
                border="1px dashed gray"
                p={4}
                textAlign="center"
                cursor="pointer"
                w="100%"
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="gray.50"
              >
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected_Image"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                ) : (
                  <img
                    src={formData.cv_photo}
                    alt="Selected"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                )}
              </Box>
            </label>
            {selectedImage && (
              <VStack mt={2} align="start">
                <Button onClick={handleRemoveFile} colorScheme="red" size="sm">
                  Remove File
                </Button>
              </VStack>
            )}

            <Button
              isLoading={EditLoading}
              float="inline-end"
              type="submit"
              colorScheme="blue"
              mt={4}
              sx={{
                bgColor: "#5c90e9",
                transitionDuration: "500ms",
                color: "white",
                _hover: {
                  bgColor: "#185aca",
                },
                mb: 3,
              }}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(ServiceEditModal);
