import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ContactsInfoDataType } from "../../Components/admin_dashboard_panel/ContactUs/AdminContact";

interface ContactInfoSliceState {
  isLoading: boolean;
  data: ContactsInfoDataType[];
  error: Error | null;
}

const initialState: ContactInfoSliceState = {
  isLoading: false,
  data: [],
  error: null,
};

export const contactInfoSlice = createSlice({
  name: "contactInfoData",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setContactInfoDatas: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading, setContactInfoDatas } = contactInfoSlice.actions;

export default contactInfoSlice.reducer;
