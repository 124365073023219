import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Text,
  Box,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { Editor } from "@ckeditor/ckeditor5-core";
import { useAppSelector } from "../../../../store/hooks";
import { AdminDataType } from "../../../../types/AdminDataType";
import { getToken } from "../../../../lib/auth";
import { setCreateLoading } from "../../../../store/slices/globalSlice";
import { FaStarOfLife } from "react-icons/fa6";

interface CreateAdminModalProps {
  title: string; // Data to be edited
  fetchData: () => void;
}

export interface CreateAdminModalRef {
  open: () => void; // Updated to accept data object
  close: () => void;
}

const AdminCreateModal: React.ForwardRefRenderFunction<
  CreateAdminModalRef,
  CreateAdminModalProps
> = ({ title, fetchData }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState<AdminDataType>({
    name: "",
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const CreateLoading = useAppSelector((state) => state.global.createLoading);
  const toast = useToast();
  const access_token = getToken();

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen();
    },
    close: onClose,
  }));

  // console.log("formData :: ", formData);

  const formFillingFun = (type: string, value: string) => {
    setFormData({ ...formData, [type]: value });
  };

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, id: any) => {
    e.preventDefault();
    dispatch(setCreateLoading(true));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      // console.log("formData.base64Data :: ", formData.base64Data);
      if (data.code === 400) {
        toastFun("Error", data.message, "error");
        dispatch(setCreateLoading(false));
        onClose();
        return;
      } else if (data.code === 200) {
        toastFun("Success", data.message, "success");
        dispatch(setCreateLoading(false));
        setFormData({
          name: "",
          email: "",
          password: "",
        });
        onClose();
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log("formData :: ", formData);

  // Render modal content
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{
          base: "100%",
          sm: "90%",
          md: "70%",
          lg: "60%",
          xl: "40%",
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={(e) => handleSubmit(e, formData.id)}>
            <FormControl>
              <FormLabel>
                <div className=" flex">
                  Name{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                mb="10px"
                bgColor={"neat.pearlwhite"}
                value={formData.name}
                fontWeight="500"
                size="lg"
                placeholder="Name"
                onChange={(e) => formFillingFun("name", e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <div className=" flex">
                  Email{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                type="email"
                isRequired={true}
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                mb="10px"
                bgColor={"neat.pearlwhite"}
                value={formData.email}
                fontWeight="500"
                size="lg"
                placeholder="Email"
                onChange={(e) => formFillingFun("email", e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <div className=" flex">
                  Password{" "}
                  <FaStarOfLife className=" text-[9px] my-auto ml-1 text-red-800" />
                </div>
              </FormLabel>
              <Input
                type="password"
                isRequired={true}
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                mb="10px"
                bgColor={"neat.pearlwhite"}
                value={formData.password}
                fontWeight="500"
                size="lg"
                placeholder="Password"
                onChange={(e) => formFillingFun("password", e.target.value)}
              />
            </FormControl>

            <Button
              isLoading={CreateLoading}
              float="inline-end"
              type="submit"
              colorScheme="blue"
              mt={4}
              sx={{
                bgColor: "#5c90e9",
                transitionDuration: "500ms",
                color: "white",
                _hover: {
                  bgColor: "#185aca",
                },
                mb: 3,
              }}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(AdminCreateModal);
