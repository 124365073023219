import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  useDisclosure,
  Image,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

import { FaHandPointRight, FaLongArrowAltRight } from "react-icons/fa";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { config } from "../../config/config";
import Layout from "../../layout/Layout";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setPhotoDatas } from "../../store/slices/photosSlice";
import Pagination from "../../Components/Custom/CustomPaginate";
import { setTotal_count } from "../../store/slices/globalSlice";
import Loading from "../../Components/Custom/Loading";

const GalleryDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const galleryId = params.id as string;
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const { photoData } = useAppSelector((state) => state.photo);
  const [galleryDetail, setGalleryDetail] = useState({
    id: "",
    name: "",
    cv_photo: "",
    description: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = (imgUrl: any) => {
    setSelectedImage(imgUrl);
    onOpen();
  };
  const dispatch = useAppDispatch();

  const detailFun = async () => {
    setIsFetchLoading(true);
    const response = await fetch(
      `${config.developmentUrl}/gallery/${galleryId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // return await handleResponse(response);

    const data = await response.json();

    // if (data.status === 404) {
    //   return navigate("/");
    // }

    // const result = await data.data;

    // console.log("data :: ", data);

    setGalleryDetail(data);
    setIsFetchLoading(false);
  };

  const fetchPhotos = async () => {
    const response = await fetch(
      `${config.developmentUrl}/photos/details/${galleryId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(accessToken),
      }
    );
    const data = await response.json();
    // console.log("data :: ", data);

    // console.log("parsedProjects :: ", parsedProjects);
    dispatch(setPhotoDatas(data));
  };

  useEffect(() => {
    fetchPhotos();
    detailFun();
  }, [galleryId]);

  const backFun = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className=" w-[100%] min-h-[100vh] bg-[#CDCDCD]">
        {isFetchLoading ? (
          <div className=" w-[100%] h-[100vh] flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="pt-5  pb-16">
            <div className=" flex  justify-center">
              <div className=" md:container">
                <button
                  onClick={backFun}
                  className="flex items-center mt-16 text-white ml-3 md:ml-0 bg-[#235D4B] text-[16px] px-5 py-2 rounded-[5px] hover:bg-[#0B4D39] transition-all duration-700 font-bold"
                >
                  <IoCaretBackCircle className=" mr-1" />
                  Back
                </button>

                {/* <div className=" flex justify-center mt-5 w-[100%]">
                  <img
                    src={galleryDetail.cv_photo}
                    alt={`Gallery-CV-Photo`}
                    className=""
                  />
                </div> */}
                <div>
                  <h1 className=" mt-5 text-[23px] md:text-[27px] font-semibold text-[#0B4D39] text-center">
                    {galleryDetail.name}
                  </h1>
                </div>
                <div className=" px-3">
                  <h1 className=" my-5 text-[18px] md:text-[21px] font-semibold text-gray-600">
                    Description
                  </h1>
                  <span
                    className=" text-[15px] md:text-[18px] font-light text-gray-600 "
                    dangerouslySetInnerHTML={{
                      __html: galleryDetail.description,
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <Divider orientation="horizontal" className=" mt-8" />
            <div className=" flex justify-center mt-5">
              <div className=" md:container px-3">
                <div>
                  {photoData.length > 0 && (
                    <h1 className="mb-5 text-[23px] md:text-[27px] font-semibold text-[#0B4D39] text-center">
                      Our Photos
                    </h1>
                  )}

                  {/* <Swiper
                    pagination={{
                      type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {photoData.map((item, index) => (
                      <SwiperSlide key={index}>
                        <img
                          src={item.img_url}
                          alt={`Image ${index + 1}`}
                          className=""
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper> */}
                  <div className=" flex flex-wrap">
                    {photoData?.map((item, index) => {
                      return (
                        <div
                          className=" w-[95%] sm:w-[85%] md:w-[45%] lg:w-[48%] xl:w-[30%] mb-3 lg:mb-5 mx-auto cardShadow pt-5 rounded-md cursor-pointer hover:bg-[#8BB491] transition-all duration-300"
                          onClick={() => handleImageClick(item.img_url)}
                          key={item.id}
                        >
                          <div className=" md:px-5 pb-5 rounded-lg overflow-hidden">
                            <img
                              src={item.img_url}
                              alt={`Image-${index + 1}`}
                              className={`w-[100%] h-[320px] transition-all duration-700 rounded-md `}
                            />
                            {/* {item.img_url[0]} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay />
                    <ModalContent
                      minW={{
                        base: "100%",
                        sm: "90%",
                        md: "70%",
                        lg: "60%",
                        xl: "40%",
                      }}
                    >
                      {/* <ModalHeader>Image Preview</ModalHeader> */}
                      <ModalCloseButton />
                      <ModalBody py={10}>
                        {selectedImage && (
                          <Image
                            src={selectedImage}
                            alt="Selected Image"
                            w="100%"
                          />
                        )}
                      </ModalBody>
                      {/* <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                          Close
                        </Button>
                      </ModalFooter> */}
                    </ModalContent>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default GalleryDetails;
