import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Box, useBreakpointValue } from "@chakra-ui/react";
import Sidebar from "../Components/bars/Sidebar";
import AdminNavbar from "../Components/bars/AdminNavBar";
import PublicNavBar from "../Components/bars/PublicNavBar";
import Footer from "../PublicComponents/Footer";

interface Props {
  title?: string;
  children: string | JSX.Element | JSX.Element[];
}

const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

const Layout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const logInpath = pathname.includes("/login");
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });

  const avaliablePath =
    pathname.includes("/products") ||
    pathname.includes("/customers") ||
    pathname.includes("/contact");

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  const adminPanel = location.pathname.startsWith("/admin_dashboard_panel/");

  const checkingPathAndNavigationButton =
    avaliablePath && !variants?.navigationButton;

  return (
    <div className=" app min-h-[100vh]">
      {adminPanel ? (
        <Box>
          {!logInpath && (
            <Sidebar
              variant={(variants?.navigation as "drawer") || "sidebar"}
              isOpen={isSidebarOpen}
              onClose={toggleSidebar}
            />
          )}
          <Box>
            {!logInpath && (
              <AdminNavbar
                showSidebarButton={true}
                onShowSidebar={toggleSidebar}
              />
              // <AdminNavbar onShowSidebar={toggleSidebar} />
            )}
            {adminPanel && (
              <Box
                px={{ base: "0", md: logInpath ? 0 : 10 }}
                py={{ base: "0", md: logInpath ? 0 : 10 }}
                ml={checkingPathAndNavigationButton ? 0 : 0}
              >
                {props.children}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <PublicNavBar />
          {props.children}
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
