import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import GalleryItem from "./GalleryItem";
import { Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setFetchLoading } from "../store/slices/globalSlice";
import Loading from "../Components/Custom/Loading";

const Gallery = () => {
  const [data, setData] = useState<any>([]);
  const { isFetchLoading } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const fetchGallery = async () => {
    dispatch(setFetchLoading(true));
    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/gallery?page=1&limit=6`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    setData(data.galleries);
    dispatch(setFetchLoading(false));
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  return (
    <div className="mt-10">
      <div className=" flex justify-center mb-10 md:mb-8 lg:mb-0">
        <h1 className=" text-center self-center text-[23px] lg:text-[30px] font-bold lg:mt-0">
          <span className="text-[#0B4D39] text-[32px]">Our Galleries</span>
          <Divider
            orientation="horizontal"
            sx={{
              borderColor: "#123456", // Replace with your custom color
              borderBottom: "4px",
              width: "30%",
              mx: "auto",
              mt: 1,
            }}
          />
        </h1>
      </div>

      {isFetchLoading ? (
        <div className=" mt-5">
          <Loading />
        </div>
      ) : (
        <div className=" flex flex-wrap justify-center lg:pt-10">
          {data?.map((item: any, index: any) => (
            <Link
              key={item.id}
              to={`/gallery/${item.id}`}
              className=" "
              // onClick={() => console.log("item.id :: ", item.id)}
            >
              <GalleryItem key={item.id} item={item} index={index} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
