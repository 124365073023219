import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
// import { GalleryDataType } from "../../types/GalleryDataType";
import { PhotosDataType } from "../../types/PhotosDataType";

interface PhotoSliceState {
  isLoading: boolean;
  photoData: PhotosDataType[];
  error: Error | null;
}

const initialState: PhotoSliceState = {
  isLoading: false,
  photoData: [],
  error: null,
};

export const photoSlice = createSlice({
  name: "photoData",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setPhotoDatas: (state, action) => {
      state.photoData = action.payload;
    },
  },
});

export const { setIsLoading, setPhotoDatas } = photoSlice.actions;

export default photoSlice.reducer;
