import React from "react";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

const ContactInfoBox = () => {
  return (
    <div className="w-[100%] py-10">
      <div className=" flex text-[#CDCDCD] text-[20px] mb-8 items-center ">
        <div className=" w-[20%] sm:w-[17%] lg:w-[10%] text-[25px]">
          <FaEnvelope />
        </div>
        <a
          className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
          href="mailto:contact@rstmyanmar.com"
        >
          contact@rstmyanmar.com
        </a>
      </div>
      <div className=" flex text-[#CDCDCD] text-[20px] mb-8 items-center ">
        <div className=" w-[20%] sm:w-[17%] lg:w-[10%] text-[25px]">
          <FaPhoneAlt />
        </div>
        <div className="">
          <a
            className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
            href="tel:(+95) 9250816883"
          >
            {" "}
            +959250816883
            {" , "}
          </a>
          <a
            className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
            href="tel:(+95) 9428921317"
          >
            {" "}
            +959428921317
          </a>
        </div>
      </div>
      <div className=" flex text-[#CDCDCD] text-[20px] mb-8 items-center ">
        <div className=" w-[20%] sm:w-[17%] lg:w-[10%] text-[25px]">
          <FaFacebookSquare />
        </div>
        <a
          className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
          href="https://www.facebook.com/RoyalSmartTechMM"
          target="_blank"
          rel="noopener noreferrer"
        >
          Royal Smart-Tech Myanmar
        </a>
      </div>
      <div className=" flex text-[#CDCDCD] text-[20px] mb-8 items-center ">
        <div className=" min-w-[20%] sm:w-[17%] lg:min-w-[10%] text-[25px]">
          <FaMapMarkerAlt />
        </div>
        <a
          className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
          href="https://www.google.com/maps?ll=19.732586,96.148942&z=19&t=m&hl=en&gl=MM&mapclient=embed"
        >
          NO. 167,CORNER OF THAZIN STREET & HNIN PAN STREET, AUNG ZABU QUARTER,
          ZABUTHIRI TOWNSHIP, NAYPYITAW, MYANMAR.
        </a>
      </div>
      {/* <div className=" flex text-[#CDCDCD] text-[20px] mb-8 items-center ">
        <div className=" w-[20%] sm:w-[17%] lg:w-[10%] text-[25px]">
          <FaMapMarkerAlt />
        </div>
        <a
          className="text-[17px] lg:text-[19px] hover:text-[#0490DD] transition-all duration-300"
          href="https://www.google.com/maps?ll=19.732586,96.148942&z=19&t=m&hl=en&gl=MM&mapclient=embed"
        >
          Aungzabu Qtr, Part Eight, Myat Lay St, No.10 , Nay Pyi Taw ;
        </a>
      </div> */}
    </div>
  );
};

export default ContactInfoBox;
