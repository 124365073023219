import React from "react";
import { BiSolidToTop } from "react-icons/bi";

interface FloatingButtonProps {
  onClick: () => void;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-20 right-4 bg-[#8bb491] text-white p-3 rounded-full shadow-lg hover:bg-[#75A595] transition-colors"
    >
      <BiSolidToTop className=" text-[#0B4D39] text-[23px]" />
    </button>
  );
};

export default FloatingButton;
