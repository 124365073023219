import React from "react";
import {
  Badge,
  Center,
  Divider,
  Flex,
  Img,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPowerOff } from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { navItems } from "../../constants/constants";
import CustomModal from "../Custom/CustomModal";
import { removeAuth } from "../../lib/auth";
// import { useStore } from "../store/store";

interface SidebarContentProps {
  onClose?: VoidFunction;
}

const SidebarContent: React.FC<SidebarContentProps> = ({ onClose }) => {
  // const pathname = usePathname();
  // const { projects, setProjects, unreadCount } = useStore((state) => state);

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure();
  const navigate = useNavigate();

  const LogoutFun = () => {
    // console.log("logout:::");
    removeAuth();
    navigate("/admin_dashboard_panel/login");
  };

  return (
    <>
      <Center flex="1" mb={10} flexDirection={"column"}>
        <Flex alignItems="center" gap={4}>
          <Link to={"/admin_dashboard_panel/"}>
            <img
              // src={"/pmg.jpg"}
              src={"/royal_smart_tech.png"}
              alt=""
              className=" w-[60px] h-[50px] cursor-pointer rounded-[100%] overflow-hidden"
            />
          </Link>
        </Flex>
        <Flex className=" mt-2 text-[22px] text-black font-bold">R.S.T.M</Flex>
        {/* <Flex className=" mt-2 text-[22px] text-black font-bold">P.M.G</Flex> */}
      </Center>
      <Divider
        orientation="horizontal"
        mt="-25px"
        mb="8"
        sx={{
          borderColor: "#123456", // Replace with your custom color
        }}
      />
      <Flex direction="column" rowGap={2}>
        {navItems.map((item) => (
          <NavLink
            key={item.title}
            to={item.route}
            className="bg-[#b4b3b3] pb-3 text-[#000] hover:bg-[#75a595] pl-4 rounded-lg"
          >
            <p className=" mt-3">{item.title}</p>
          </NavLink>
        ))}
        <div
          className="bg-[#b4b3b3] pb-3 text-[#000] hover:bg-[#75a595] pl-4 rounded-lg cursor-pointer"
          onClick={() => onOpen()}
        >
          <div className=" flex align-middle">
            <FaPowerOff className=" mt-4 text-[#b42727]" />
            <p className=" mt-3 ml-2 ">Log Out</p>
          </div>
        </div>
        <CustomModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onCloseModal}
          modalTitle={"Log Out"}
          modalText={"Are you sure to logout??"}
          actionFun={LogoutFun}
          actionText={"Log Out"}
        />
      </Flex>
    </>
  );
};

export default SidebarContent;
