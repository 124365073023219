import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  NavLink,
  Link as ReactLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { useStore } from "../store/store";
import { Link, animateScroll as scroll } from "react-scroll";
import { Box, Button, List, ListItem } from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { FaSignOutAlt } from "react-icons/fa";
import ThemeToggle from "../../Theme/ThemeToggle";
import { getToken } from "../../lib/auth";
import { FaHome } from "react-icons/fa";
// import { removeAuth } from "../lib/auth";
// import { getAccessToken } from "../utils/validation";

export const tabs = [
  { id: 1, name: "Home", route: "Home" },
  { id: 2, name: "About", route: "About" },
  { id: 3, name: "Gallery", route: "Gallery" },
  { id: 4, name: "Services", route: "Services" },
  { id: 5, name: "Contact", route: "Contact" },
];

const PublicNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const adminPanel = location.pathname.startsWith("/admin_dashboard_panel");
  const galleriesDetailsPage = location.pathname.startsWith("/gallery");
  // const customersDetailsPage = location.pathname.startsWith("/customers");
  const isLoginPage = location.pathname.endsWith("/login");
  //   const navigate = useNavigate();
  // const { selectedTab, setSelectedTab } = useStore((state) => state);
  const [isRes, setIsRes] = useState(false);
  const access_token = getToken();
  // console.log("access_token :: ", access_token);

  const [selectedTab, setSelectedTab] = useState("Home");
  // const [adminTab, setAdminTab] = useState(5);

  // const accessToken = getAccessToken();
  // Defining functions to perform different types of scrolling.

  const handleRes = () => {
    setIsRes((prev) => !prev);
  };
  const closeNavbar = () => {
    // Toggle the visibility of the navbar
    setIsRes(false);
  };

  const handleSetActive = (tabName: any) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    // Default select "Home" tab on initial load
    setSelectedTab("Home");
  }, []);

  // console.log("selectedTab :: ", selectedTab);

  return (
    <div className=" flex justify-center py-3 bg-[#CDCDCD] dark:bg-[#373737] fixed w-[100%] z-50 navBarShadow">
      <div className=" container flex justify-between items-center">
        <div className=" flex">
          <ReactLink to={"/"} className=" flex">
            <div className=" my-auto ml-3 md:ml-0">
              <img
                src={"/royal_smart_tech.png"}
                alt=""
                className=" w-[44px] h-[44px] cursor-pointer rounded-[100%]"
              />
            </div>
            <div className=" my-auto">
              <div className=" md:text-[19px] lg:text-[21px] font-bold text-[#0B4D39] dark:text-[#CDCDCD] ml-0 lg:ml-2 text-center cursor-pointer">
                Royal Smart-Tech Myanmar
                {/* <span className=" ml-2 text-[21px] lg:text-[25px] text-[#0B4D39]">
                  Co.ltd
                </span> */}
              </div>
            </div>
          </ReactLink>
        </div>

        {!adminPanel && !galleriesDetailsPage && (
          <div className=" hidden lg:flex">
            <ul className=" flex">
              {tabs.map((item) => {
                return (
                  <Link
                    to={item.route}
                    spy={true}
                    smooth={true}
                    key={item.id}
                    offset={-100}
                    duration={700}
                    delay={0}
                    // onSetActive={() => handleSetActive(item.name)}
                    onSetActive={() => handleSetActive(item.name)}
                    className=" mr-2 cursor-pointer"
                  >
                    <li
                      key={item.id}
                      className=" px-5 transition-all duration-500 hover:bg-[#8bb491] dark:hover:bg-[#506152] hover:rounded-md md:w-[90px] lg:w-[110px] md:py-[5px] text-center"
                    >
                      <span
                        className={
                          " md:text-[14px] lg:text-[16px] font-semibold text-[#094638] dark:text-[#CDCDCD] z-50"
                        }
                      >
                        {item.name}
                      </span>
                      {/* {item.name === selectedTab && (
                        <motion.div
                          className="underline"
                          layoutId="underline"
                        />
                      )} */}
                    </li>
                  </Link>
                  // <h1 key={item.id}>hello</h1>
                );
              })}
            </ul>
            {access_token && (
              <Button
                size="sm"
                py={"3.5px"}
                className=" text-[#75a595]"
                onClick={() => navigate("/admin_dashboard_panel/dashboard")}
              >
                <FaHome className=" mr-2" /> Dashboard
              </Button>
            )}
            {/* <ThemeToggle /> */}
          </div>
        )}

        {!adminPanel && (
          <Box
            className=" flex lg:hidden"
            zIndex={100}
            alignItems="center"
            justifyContent="space-between"
          >
            {/* toggle */}
            <Box as="button" onClick={handleRes} zIndex="100">
              {isRes ? (
                <RxCross2 size={25} className=" text-[25px] text-white mr-4" />
              ) : (
                <FiMenu className=" text-[25px] text-[rgba(51,112,113)] mr-4" />
              )}
            </Box>
          </Box>
        )}
        {isRes && (
          <Box
            display={{ sm: "flex", xl: "none" }}
            // flexDir="column-reverse"
            // justifyContent="space-evenly"
            position="fixed"
            top="0"
            right="0"
            className=" bg-[rgba(51,112,113)] w-[70%] sm:w-[50%] md:w-[45%]"
            height="100vh"
            zIndex={80}
            // transition={{ exit: { delay: 1 }, enter: { duration: 0.6 } }}
          >
            <Box
              display="flex"
              flexDir="column"
              gap="3"
              // justifyContent="space-around"
              className=" w-[100%] pt-20"
            >
              <List
                display="grid"
                alignItems="center"
                gap="2"
                fontSize="lg"
                fontWeight="600"
                className=" w-[100%]"
              >
                {tabs?.map((link, index) => (
                  <Box key={index} cursor="pointer" className=" w-[100%]">
                    <Link
                      activeClass=""
                      to={link.route}
                      spy={true}
                      smooth={true}
                      key={link.id}
                      offset={-100}
                      duration={700}
                      delay={0}
                      onSetActive={() => handleSetActive(link.name)}
                    >
                      <li
                        onClick={handleRes}
                        className="w-full px-10 py-3 transition-all duration-500 text-white text-[17px] rounded-lg hover:bg-[#678F90]"
                      >
                        {link?.name}
                      </li>
                    </Link>
                  </Box>
                ))}
              </List>
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

export default PublicNavBar;
