import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ServiceDataType } from "../../types/ServiceDataType";

interface ServiceSliceState {
  isLoading: boolean;
  data: ServiceDataType[];
  error: Error | null;
}

const initialState: ServiceSliceState = {
  isLoading: false,
  data: [],
  error: null,
};

export const serviceSlice = createSlice({
  name: "serviceData",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setServiceDatas: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading, setServiceDatas } = serviceSlice.actions;

export default serviceSlice.reducer;
