import React, { useRef } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";
import TruncatedText from "../Components/Custom/TruncatedText";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ServiceDetailModal, {
  DetailServiceModalRef,
} from "../Components/admin_dashboard_panel/Services/ServicesModal/ServiceDetailModal";
import { ServiceDataType } from "../types/ServiceDataType";

const ServiceItem = ({ item, index }: any) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const serviceDetailModalRef = useRef<DetailServiceModalRef>(null);

  const handleDetailModal = (servicedata: ServiceDataType) => {
    // console.log("service detail Modal :: ", servicedata);
    if (serviceDetailModalRef.current) {
      serviceDetailModalRef.current.open({ ...servicedata });
    }
  };

  //   dark:bg-[#575656]

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      onClick={() => handleDetailModal(item)}
    >
      <Card
        maxW="md"
        transition=" 0.2s ease-in-out"
        cursor={"pointer"}
        mb={10}
        mx={5}
        overflow={"hidden"}
        rounded={"md"}
        // className="serviceCardShadow"
        boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
        _hover={{
          transform: "scale(1.01)",
        }}
      >
        <CardBody className=" bg-[#CDCDCD] dark:bg-[#888282]">
          <Image
            src={item.cv_photo}
            alt="Gallery cover photo"
            borderRadius="lg"
            w={"450px"}
            h={"270px"}
            objectFit={"cover"}
          />
          <Stack mt="6" spacing="3" className="h-auto">
            <Heading size="md" className=" mb-2 font-semibold">
              {item.name}
            </Heading>
            {/* <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    bg={"#9ac4b6"}
                    _expanded={{ bg: "#0B4D39", color: "white" }}
                    borderRadius="lg"
                    _hover={{ bg: "#648077", color: "#fff" }}
                    transition={"all"}
                    transitionDuration={"500ms"}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      {item.name} Service
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    className=" pt-5"
                  ></div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </Stack>
        </CardBody>
      </Card>
      <ServiceDetailModal
        title={item.name + " " + "Service"}
        ref={serviceDetailModalRef}
      />
    </motion.div>
  );
};

export default ServiceItem;
