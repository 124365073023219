import { Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ServiceItem from "./ServiceItem";
import { useAppSelector } from "../store/hooks";
import Loading from "../Components/Custom/Loading";

const Services = () => {
  const [data, setData] = useState<any>([]);
  const { isFetchLoading } = useAppSelector((state) => state.global);

  const fetchGallery = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/services?page=1&limit=6`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    setData(data.servicesData);
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  return (
    <div className="my-10">
      <div className=" flex justify-center">
        <h1 className=" text-center self-center text-[23px] lg:text-[30px] font-bold lg:mt-8">
          <span className="text-[#0B4D39] text-[32px]">Our Services</span>
          <Divider
            orientation="horizontal"
            sx={{
              borderColor: "#123456", // Replace with your custom color
              borderBottom: "4px",
              width: "30%",
              mx: "auto",
              mt: 1,
            }}
          />
        </h1>
      </div>
      {isFetchLoading ? (
        <div className=" mt-10">
          <Loading />
        </div>
      ) : (
        <div className=" flex flex-wrap justify-center pt-10">
          {data?.map((item: any, index: any) => (
            <ServiceItem key={item.id} item={item} index={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Services;
