import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Input,
  TableContainer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import {
  FaRegTrashAlt,
  FaRegEdit,
  FaTrashRestore,
  FaSistrix,
  FaAngleDoubleLeft,
  FaTrash,
} from "react-icons/fa";
import React, { useEffect, useMemo, useRef, useState } from "react";
import usePagination from "../../../hooks/usePagination";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setDeleteLoading,
  setFetchLoading,
  setTotal_count,
} from "../../../store/slices/globalSlice";
import { getToken } from "../../../lib/auth";
import { setAdminDatas } from "../../../store/slices/adminSlice";
import { AdminDataType } from "../../../types/AdminDataType";
import CustomTable from "../../Custom/CustomTable";
import Layout from "../../../layout/Layout";
import AdminCreateModal, {
  CreateAdminModalRef,
} from "./AdminModal/AdminCreateModal";
import AdminEditModal, { EditAdminModalRef } from "./AdminModal/AdminEditModal";
import CustomModal from "../../Custom/CustomModal";
import Loading from "../../Custom/Loading";

const AdminManagement = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isForceDeleteOpen,
    onOpen: onForceDeleteOpen,
    onClose: onForceDeleteClose,
  } = useDisclosure();
  const { onPaginationChange, pagination } = usePagination();
  const [adminDataForDelete, setAdminDataForDelete] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();
  const { credential, fetchDataStatus } = useAppSelector(
    (state) => state.global
  );
  const [deleteAdminId, setDeleteAdminId] = useState("");
  const { data } = useAppSelector((state) => state.admin);
  const { total_count, isFetchLoading } = useAppSelector(
    (state) => state.global
  );
  const access_token = getToken();
  const toast = useToast();
  const pageCount = total_count
    ? Math.ceil(total_count / pagination.pageSize)
    : 0;

  const FetchGetAllAdminListFun = async () => {
    dispatch(setFetchLoading(true));
    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/admin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    const data = await response.json();
    // console.log("data :: ", data);

    dispatch(setAdminDatas(data?.admin));
    dispatch(setFetchLoading(false));
  };

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    FetchGetAllAdminListFun();
  }, []);

  const createAdminModalRef = useRef<CreateAdminModalRef>(null);

  const createAdminModalFun = () => {
    if (createAdminModalRef.current) {
      createAdminModalRef.current.open();
    }
  };

  const editAdminModalRef = useRef<EditAdminModalRef>(null);

  const editAdminModalFun = (data: AdminDataType) => {
    if (editAdminModalRef.current) {
      editAdminModalRef.current.open(data);
    }
  };

  const deleteComfirmFun = async () => {
    dispatch(setDeleteLoading(true));
    if (deleteAdminId) {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/admin/${deleteAdminId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const data = await response.json();

      if (data.code === 200) toastFun("Success", data.message, "success");
      if (data.code === 400 || data.code === 404)
        toastFun("Error", data.message, "error");
      dispatch(setDeleteLoading(false));
      onClose();
      FetchGetAllAdminListFun();
    }
  };

  const handleDelete = (id: string) => {
    setDeleteAdminId(id);
    onOpen();
  };

  const columns = useMemo<ColumnDef<AdminDataType, React.ReactNode>[]>(
    () => [
      {
        header: "No",
        accessorFn: (_row, index) => index + 1,
        id: "serialNumber",
      },
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "actions",
        cell: ({ row }: CellContext<AdminDataType, React.ReactNode>) => (
          <>
            <Flex gap={3}>
              <Button
                onClick={() => {
                  editAdminModalFun(row.original);
                }}
                sx={{
                  bgColor: "#5c90e9",
                  transitionDuration: "500ms",
                  color: "white",
                  _hover: {
                    bgColor: "#185aca",
                  },
                }}
              >
                <FaRegEdit />
              </Button>
              <Button
                onClick={() => {
                  row.original && handleDelete(row.original?.id as string);
                }}
                sx={{
                  bgColor: "#EE5D50",
                  color: "white",
                  transitionDuration: "500ms",
                  _hover: {
                    bgColor: "#E31A1A",
                  },
                }}
              >
                <FaRegTrashAlt />
              </Button>
            </Flex>
          </>
        ),
      },
    ],
    []
  );

  return (
    <Layout>
      <Container pt={10} maxW="100%">
        <Box>
          <Text
            fontSize={"30px"}
            fontWeight={"bold"}
            // cursor={"pointer"}
            className=" pt-10 md:pt-0 ml-3 md:ml-0"
          >
            Account Control
          </Text>
          <Box display={"flex"} className="">
            <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
              <Button
                colorScheme={"blue"}
                isDisabled={isFetchLoading}
                ml={4}
                onClick={createAdminModalFun}
                sx={{
                  bgColor: "#5c90e9",
                  transitionDuration: "500ms",
                  color: "white",
                  _hover: {
                    bgColor: "#185aca",
                  },
                }}
                className=" mr-3 md:mr-0"
              >
                Add Admin
              </Button>
            </Box>
          </Box>

          {isFetchLoading ? (
            <div className=" h-[55vh] lg:h-[70vh]">
              <Loading />
            </div>
          ) : (
            <TableContainer>
              <CustomTable
                data={data}
                columns={columns}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                pageCount={pageCount}
              />
            </TableContainer>
          )}
          {/* {isFetchLoading ? (
            <Loading />
          ) : (
            <TableContainer>
              <CustomTable
                data={userData}
                columns={columns}
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                pageCount={pageCount}
              />
            </TableContainer>
          )} */}
        </Box>
        <AdminCreateModal
          title={"Add Admin"}
          fetchData={FetchGetAllAdminListFun}
          ref={createAdminModalRef}
        />
        <AdminEditModal
          title={"Edit Admin"}
          fetchData={FetchGetAllAdminListFun}
          ref={editAdminModalRef}
        />
        <CustomModal
          modalTitle={"Delete"}
          modalText={`Are you sure to Delete Admin ?`}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          actionFun={deleteComfirmFun}
          actionText={"Delete"}
        />
      </Container>
    </Layout>
  );
};

export default AdminManagement;
