import { Box } from "@chakra-ui/react";
import { useState } from "react";

interface Props {
  text: string;
  id?: string;
}

const TruncatedText = ({ text, id }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const maxChars = 100; // Set the maximum number of characters to display when not expanded

  // Truncate the text while preserving spaces and newlines
  const truncatedText = isExpanded ? text : text.slice(0, maxChars).trimEnd();

  // Add ellipsis if the text is truncated and not expanded
  const displayText = isExpanded
    ? text
    : truncatedText.length < text.length
    ? `${truncatedText}`
    : truncatedText;

  return (
    <div>
      <Box
        className={`truncated-text ${isExpanded ? "expanded" : ""}`}
        lineHeight={6}
        whiteSpace="pre-wrap" // Preserve spaces and newlines
      >
        <div dangerouslySetInnerHTML={{ __html: displayText }} />
        {text.length > maxChars && (
          <span
            onClick={toggleExpanded}
            style={{
              cursor: "pointer",
              color: "#194148",
              fontSize: "13px",
            }}
          >
            {isExpanded ? " ... less" : " . . . . . ."}
          </span>
        )}
      </Box>
    </div>
  );
};

export default TruncatedText;
