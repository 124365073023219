import React from "react";
import Layout from "../layout/Layout";
import { Divider, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const About = () => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const [ref1, inView1] = useInView({ threshold: 0.1, triggerOnce: true });
  const [ref2, inView2] = useInView({ threshold: 0.1, triggerOnce: true });

  React.useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls1, inView1]);

  React.useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    }
  }, [controls2, inView2]);

  const parentVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, delay: 0.5 },
    },
  };

  return (
    <div className=" md:mt-14 lg:mt-0 mb-0 md:mb-14">
      <div className=" flex justify-center mb-10">
        <h1 className=" text-center self-center text-[23px] lg:text-[30px] font-bold lg:mt-8">
          <span className="text-[#0B4D39] text-[32px]">About Us</span>
          <Divider
            sx={{
              borderColor: "#123456", // Replace with your custom color
              borderBottom: "4px",
              width: "30%",
              mx: "auto",
              mt: 1,
            }}
          />
        </h1>
      </div>
      <motion.div
        ref={ref1}
        animate={controls1}
        initial="hidden"
        variants={parentVariants}
        className="min-h-[100vh] bg-[#363434] rounded-[35px] overflow-hidden lg:rounded-[5%] py-6 lg:py-24 componentShadow"
      >
        <div>
          <h1 className=" text-white text-center text-[28px] lg:text-[32px] mb-10">
            Company Timeline
          </h1>
        </div>
        <motion.div
          animate={controls1}
          initial="hidden"
          variants={childVariants}
          className="h-[50%] block lg:flex justify-center w-[100%] gap-10"
        >
          <div className="lg:w-[40%] block md:hidden px-5">
            <img
              src="https://img.jagranjosh.com/images/2022/February/222022/Top-10-most-valuable-companies-in-the-world.jpg"
              alt="img"
              className="mx-auto rounded-2xl lg:rounded w-[100%] h-[400px]-lg"
            />
          </div>

          <div className="lg:w-[40%] py-5 px-5 text-[18px] my-auto flex">
            <Divider
              height="auto"
              borderColor="white"
              borderWidth="2px"
              orientation="vertical"
              mr={5}
              className=" hidden md:flex"
            />

            <Text className="text-[#CDCDCD] text-[16px] lg:text-[20px] text-justify tracking-tight">
              Royal Smart-Tech Myanmar Company Limited was established in 18th
              March, 2022. Royal Smart-Tech Myanmar is a turnkey engineering
              services provider and system integrator in Myanmar offering top
              quality solutions to government ministries, international
              organizations and private companies. Our ultimate goal is to build
              a great, truly world-class company that makes a fair profit, has
              heart for its employees, while contributing to our customers in
              Myanmar and across the globe through social responsibility,
              teamwork, honesty and family spirit.
            </Text>
          </div>

          <div className="lg:w-[40%] hidden md:block">
            <img
              src="https://img.jagranjosh.com/images/2022/February/222022/Top-10-most-valuable-companies-in-the-world.jpg"
              alt="img"
              className="mx-auto rounded-lg w-[100%] h-[400px]"
            />
          </div>
        </motion.div>
        <Divider className=" block md:hidden my-3" />
        <motion.div
          ref={ref2}
          animate={controls2}
          initial="hidden"
          variants={parentVariants}
          className=" bg-[#363434] rounded-[35px] overflow-hidden lg:rounded-[5%] lg:py-20"
        >
          <motion.div
            animate={controls2}
            initial="hidden"
            variants={childVariants}
            className="h-[50%] block lg:flex justify-center w-[100%] gap-10 mt-5"
          >
            <div className="lg:w-[40%] block md:hidden px-5">
              <img
                src="https://housing.com/news/wp-content/uploads/2023/08/Top-IT-companies-in-Trichy-f.jpg"
                alt="img"
                className="mx-auto rounded-2xl lg:rounded w-[100%] h-[400px]-lg"
              />
            </div>

            <div className="lg:w-[40%] hidden md:block">
              <img
                src="https://housing.com/news/wp-content/uploads/2023/08/Top-IT-companies-in-Trichy-f.jpg"
                alt="img"
                className="mx-auto rounded-lg w-[100%] h-[400px]"
              />
            </div>

            <div className="lg:w-[40%] py-5 px-5 text-[18px] my-auto flex">
              <Divider
                height="auto"
                borderColor="white"
                borderWidth="2px"
                orientation="vertical"
                mr={5}
                className=" hidden md:flex"
              />

              <Text className="text-[#CDCDCD] text-[16px] lg:text-[20px] text-justify tracking-tight">
                We have well exposed in the engineering field and are greatly
                experienced in providing complete turnkey solutions that can
                open new doors for our clients’ business. Our solid reputation
                is built upon our extensive skills, expertise and exceptional
                customer service. We are now booming our horizon to Global tier
                after building strategic partnerships with mega companies
                specializing in Security & Safety, IT infrastructure,
                Intelligence, Cyber security, radio equipment manufacturing,
                Telecommunications, military equipment manufacturing and
                supplies.
              </Text>
            </div>
          </motion.div>
        </motion.div>
        <motion.div
          className=" mt-5 mb-10"
          animate={controls1}
          initial="hidden"
          variants={childVariants}
        >
          <div className=" mb-4">
            <h1 className=" text-white text-center text-[28px] lg:text-[32px]  ">
              Our Vision
            </h1>
            <Divider w={"50px"} mx={"auto"} borderWidth="1.5px" mt={2} />
          </div>
          <div className=" flex justify-center">
            <Text className=" text-center text-white text-[16px] lg:text-[20px] font-thin w-[80%] lg:w-[100%]">
              The Tech helps build a civil society that enables everyone to
              succeed in a world driven by technology.
            </Text>
          </div>
        </motion.div>

        <motion.div
          className=" mt-5"
          animate={controls1}
          initial="hidden"
          variants={childVariants}
        >
          <div className=" mb-4">
            <h1 className=" text-white text-center text-[28px] lg:text-[32px]  ">
              Our Mission
            </h1>
            <Divider w={"50px"} mx={"auto"} borderWidth="1.5px" mt={2} />
          </div>
          <div className="">
            <Text className=" text-center text-white text-[16px] lg:text-[20px] font-thin w-[80%] lg:w-[100%] mx-auto mb-3 lg:mb-0">
              We value the trust and respect of our community and coworkers.
            </Text>
            <Text className=" text-center text-white text-[16px] lg:text-[20px] font-thin w-[80%] lg:w-[100%] mx-auto mb-3 lg:mb-0">
              We celebrate the power of technology to transform lives.
            </Text>
            <Text className=" text-center text-white text-[16px] lg:text-[20px] font-thin w-[80%] lg:w-[100%] mx-auto mb-3 lg:mb-0">
              We commit to helping people use technology creatively and
              ethically.
            </Text>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default About;
