import { ChakraProvider } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Element, scroller } from "react-scroll";
import FloatingButton from "./Components/Custom/FloatingButton";
import About from "./PublicComponents/About";
import Contact from "./PublicComponents/Contact";
import Gallery from "./PublicComponents/Gallery";
import Home from "./PublicComponents/Home";
import Services from "./PublicComponents/Services";
import Layout from "./layout/Layout";
import PartnersImgSlide from "./PublicComponents/PartnersImgSlide";
import Wrapper from "./Components/Custom/Wrapper";

const App = () => {
  const handleFloatingButtonClick = () => {
    scroller.scrollTo("Home", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    // You can add any functionality here, such as navigating to a different section or opening a modal
  };

  return (
    <ChakraProvider>
      <Wrapper fullScreen>
        <Layout>
          <div className="App min-h-screen flex flex-col bg-[#CDCDCD] text-light-text dark:text-dark-text dark:bg-[#373737]">
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <Element name="Home">
                <Home />
              </Element>
            </motion.div>

            <Element name="About">
              <About />
            </Element>
            <Element name="Gallery">
              <Gallery />
            </Element>
            <Element name="Services">
              <Services />
            </Element>
            <PartnersImgSlide />

            <Element name="Contact">
              <Contact />
            </Element>
          </div>
          <FloatingButton onClick={handleFloatingButtonClick} />
        </Layout>
      </Wrapper>
    </ChakraProvider>
  );
};

export default App;
