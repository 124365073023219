export const navItems = [
  {
    id: 1,
    title: "Dashboard",
    route: "/admin_dashboard_panel/dashboard",
  },
  {
    id: 2,
    title: "Album",
    route: "/admin_dashboard_panel/gallery",
  },
  {
    id: 3,
    title: "Services",
    route: "/admin_dashboard_panel/services",
  },
  {
    id: 4,
    title: "Account Control",
    route: "/admin_dashboard_panel/admin",
  },
  {
    id: 5,
    title: "Contact Messages",
    route: "/admin_dashboard_panel/contact",
  },
  // {
  //   id: 3,
  //   title: "Log Out",
  //   route: "/admin_dashboard_panel/admin",
  // },
  // {
  //   id: 3,
  //   title: "Valuable Customers",
  //   route: "/techarea_admin_dashboard_panel/customers",
  // },
  // { id: 4, title: "Contact", route: "/techarea_admin_dashboard_panel/contact" },
];
