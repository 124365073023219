import { forwardRef, useImperativeHandle, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  FormLabel,
  Input,
  useToast,
  Box,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../store/hooks";
import { setCreateLoading } from "../../../store/slices/globalSlice";
import { getToken } from "../../../lib/auth";
import { PhotosReqType } from "../../../types/PhotosDataType";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface AddPhotosModalProps {
  title: string; // Data to be edited
  fetchData: () => void;
}

export interface AddPhotosModalRef {
  open: (gallery_id: string) => void; // Updated to accept data object
  close: () => void;
}

const AddPhotosModal: React.ForwardRefRenderFunction<
  AddPhotosModalRef,
  AddPhotosModalProps
> = ({ title, fetchData }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState<string>("");
  const [formData, setFormData] = useState<PhotosReqType[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const dispatch = useDispatch();
  const CreateLoading = useAppSelector((state) => state.global.createLoading);
  const toast = useToast();
  const access_token = getToken();

  useImperativeHandle(ref, () => ({
    open: (gallery_id) => {
      onOpen();
      setId(gallery_id);
    },
    close: onClose,
  }));

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setCreateLoading(true));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/photos/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify({
            base64Data: formData.map((photo) => photo.base64Data),
          }),
        }
      );

      const data = await response.json();
      if (data.code === 400 || data.code === 404) {
        toastFun("Error", data.message, "error");
        dispatch(setCreateLoading(false));
        onClose();
        return;
      }
      toastFun("Success", data.message, "success");
      dispatch(setCreateLoading(false));
      setFormData([]);
      setSelectedImages([]);
      onClose();
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const base64Images = await Promise.all(
        Array.from(files).map((file) => convertToBase64(file))
      );

      setFormData((prevData) => [
        ...prevData,
        ...base64Images.map((base64) => ({ base64Data: base64 as string })),
      ]);

      setSelectedImages((prevImages) => [
        ...prevImages,
        ...Array.from(files).map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const convertToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveFile = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setFormData((prevData) => prevData.filter((_, i) => i !== index));
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minW={{
          base: "100%",
          sm: "90%",
          md: "70%",
          lg: "60%",
          xl: "30%",
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={(e) => handleSubmit(e)}>
            <FormLabel className=" mt-3">Add Photos</FormLabel>
            <Input
              type="file"
              onChange={handleFileChange}
              display="none"
              id="file-upload"
              multiple
              accept="image/*"
            />
            <label htmlFor="file-upload">
              <Box
                as="div"
                border="1px dashed gray"
                p={4}
                pb={8}
                textAlign="center"
                cursor="pointer"
                w="100%"
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="gray.50"
                flexDirection="column"
              >
                {selectedImages.length > 0 ? (
                  <Box width="100%">
                    <Slider {...sliderSettings}>
                      {selectedImages.map((img, index) => (
                        <Box key={index} position="relative" mb={2}>
                          <img
                            src={img}
                            alt={`Selected_Image_${index}`}
                            style={{ maxHeight: "380px", width: "100%" }}
                          />
                          <Button
                            size="sm"
                            colorScheme="red"
                            position="absolute"
                            top="0"
                            right="0"
                            onClick={(event) => handleRemoveFile(index, event)}
                          >
                            Remove
                          </Button>
                        </Box>
                      ))}
                    </Slider>
                  </Box>
                ) : (
                  "Please Select Your Photos"
                )}
              </Box>
            </label>

            <Button
              isLoading={CreateLoading}
              float="inline-end"
              type="submit"
              colorScheme="blue"
              mt={4}
              sx={{
                bgColor: "#5c90e9",
                transitionDuration: "500ms",
                color: "white",
                _hover: {
                  bgColor: "#185aca",
                },
                mb: 3,
              }}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(AddPhotosModal);
