import { Box } from "@chakra-ui/react";
import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@ckeditor/ckeditor5-core";

interface Props {
  data: string;
  handleChange: (event: any, editor: Editor) => void;
}

const CustomCKEditor = ({ handleChange, data }: Props) => {
  return (
    <Box>
      <CKEditor
        //@ts-ignore
        editor={ClassicEditor}
        data={data}
        onChange={(event, editor) => handleChange(event, editor)}
        config={{
          toolbar: [
            "heading",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "todoList",
            "outdent",
            "indent",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
          ],
        }}
      />
    </Box>
  );
};

export default CustomCKEditor;
