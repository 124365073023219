import React from "react";
import {
  Card,
  CardBody,
  Divider,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";
import TruncatedText from "../Components/Custom/TruncatedText";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const GalleryItem = ({ item, index }: any) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  //   dark:bg-[#575656]

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      <Card
        maxW="md"
        transition=" 0.2s ease-in-out"
        mb={10}
        boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
        mx={5}
        _hover={{
          transform: "scale(1.01)",
        }}
      >
        <CardBody className=" bg-[#CDCDCD] dark:bg-[#888282]">
          <Image
            src={item.cv_photo}
            alt="Gallery cover photo"
            borderRadius="lg"
            w={"450px"}
            h={"270px"}
            objectFit={"cover"}
          />
          <Stack mt="6" spacing="3" className="h-auto">
            <Heading size="md" textAlign={"center"} className=" text-[#0B4D39]">
              {item.name}
            </Heading>
            <Divider sx={{ my: 2 }} />
            <TruncatedText text={item.description} id={item.id} />
          </Stack>
        </CardBody>
      </Card>
    </motion.div>
  );
};

export default GalleryItem;
