import { Card, CardBody, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  route: string;
}

const DashboardCard = ({ title, route }: Props) => {
  return (
    <Link to={route} className=" flex flex-col align-middle w-[100%] md:w-auto">
      <Card
        minW={{ base: "sm", lg: "md" }}
        bg={"#a6dbca"}
        mx={1}
        mt={5}
        className=" bg-[#a6dbca] mb-5 lg:mb-10 "
        _hover={{ bg: "#8A9995", color: "#fff" }}
        transition={"all"}
        transitionDuration={"450ms"}
        cursor={"pointer"}
      >
        <CardBody>
          <Stack my="10" spacing="3">
            <Heading size="md" textAlign={"center"}>
              {title}
            </Heading>
          </Stack>
        </CardBody>
      </Card>
    </Link>
  );
};

export default DashboardCard;
