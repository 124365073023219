import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  Heading,
  Stack,
  Text,
  Image,
  useDisclosure,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import CustomModal from "../../Custom/CustomModal";
import Layout from "../../../layout/Layout";
import { getToken } from "../../../lib/auth";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import usePagination from "../../../hooks/usePagination";
import {
  setDeleteLoading,
  setFetchLoading,
  setTotal_count,
} from "../../../store/slices/globalSlice";
import TruncatedText from "../../Custom/TruncatedText";
import Pagination from "../../Custom/CustomPaginate";
import { setServiceDatas } from "../../../store/slices/serviceSlice";
import ServiceCreateModal, {
  CreateServiceModalRef,
} from "./ServicesModal/ServiceCreateModal";
import ServiceEditModal, {
  EditServiceModalRef,
} from "./ServicesModal/ServiceEditModal";
import { ServiceDataType } from "../../../types/ServiceDataType";
import ServiceDetailModal, {
  DetailServiceModalRef,
} from "./ServicesModal/ServiceDetailModal";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { IoIosEye } from "react-icons/io";
import Loading from "../../Custom/Loading";

const AdminServices = () => {
  const access_token = getToken();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isFetchLoading, createLoading } = useAppSelector(
    (state) => state.global
  );
  const { data } = useAppSelector((state) => state.service);
  const { total_count } = useAppSelector((state) => state.global);

  const dispatch = useAppDispatch();
  const { limit, onPaginationChange, skip, pagination } = usePagination();
  const [currentPage, setCurrentPage] = useState(0);
  const [serviceDataForDelete, setServiceDataForDelete] = useState<
    string | null
  >(null);

  const toast = useToast();
  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const serviceCreateModalRef = useRef<CreateServiceModalRef>(null);

  const handleCreateModal = () => {
    if (serviceCreateModalRef.current) {
      serviceCreateModalRef.current.open();
    }
  };

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const serviceEditModalRef = useRef<EditServiceModalRef>(null);

  const handleEditModal = (
    service: ServiceDataType,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    if (serviceEditModalRef.current) {
      serviceEditModalRef.current.open({ ...service });
    }
  };

  const serviceDetailModalRef = useRef<DetailServiceModalRef>(null);

  const handleDetailModal = (servicedata: ServiceDataType) => {
    console.log("service detail Modal :: ", servicedata);
    if (serviceDetailModalRef.current) {
      serviceDetailModalRef.current.open({ ...servicedata });
    }
  };

  useEffect(() => {
    getServices();
  }, [currentPage]);

  const getServices = async () => {
    dispatch(setFetchLoading(true));

    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/services?page=${
        currentPage + 1
      }&limit=8`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(setTotal_count(data.totalPages));
    dispatch(setServiceDatas(data.servicesData));
    dispatch(setFetchLoading(false));
  };

  const deleteComfirmFun = async () => {
    dispatch(setDeleteLoading(true));
    if (serviceDataForDelete) {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/services/${serviceDataForDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const data = await response.json();
      toastFun("Success", data.message, "success");
      onClose();
      getServices();
      dispatch(setDeleteLoading(false));
    }
  };

  return (
    <Layout>
      <Text
        fontSize={"30px"}
        fontWeight={"bold"}
        className=" pt-20 md:pt-10 ml-3 md:ml-0"
      >
        Services
      </Text>
      <div className="flex justify-end">
        <Button
          variant="solid"
          colorScheme="blue"
          onClick={handleCreateModal}
          className=" mr-3 md:mr-0 mb-4"
        >
          Add New Service
        </Button>
      </div>
      {isFetchLoading ? (
        <div className=" h-[55vh] lg:h-[70vh]">
          <Loading />
        </div>
      ) : (
        <div className=" flex flex-wrap gap-6 justify-center pb-28 md:pb-24">
          {data?.map((item) => (
            <Card
              maxW="md"
              key={item.id}
              transition="box-shadow 0.2s ease-in-out"
              _hover={{
                boxShadow:
                  "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
              }}
              onClick={() => handleDetailModal(item)}
              cursor={"pointer"}
            >
              <CardBody>
                <Image
                  src={item.cv_photo}
                  alt="Gallery cover photo"
                  borderRadius="lg"
                  w={"450px"}
                  h={"270px"}
                  objectFit={"cover"}
                />
                <Stack mt="6" spacing="3" className=" h-auto">
                  <Heading size="md" textAlign={"center"} color={"#0B4D39"}>
                    {item.name}
                    <Divider mt={3} />
                  </Heading>
                </Stack>
              </CardBody>
              <ButtonGroup spacing="2" ml={"auto"} mb={5} mr={5}>
                <Tooltip label="View Detail">
                  <Button
                    variant="solid"
                    bgColor="#75A595"
                    color={"white"}
                    _hover={{
                      bgColor: "#5f8578",
                      color: "white",
                    }}
                    onClick={(event) => handleDetailModal(item)}
                  >
                    <IoIosEye />
                  </Button>
                </Tooltip>
                <Tooltip label="Edit">
                  <Button
                    variant="solid"
                    colorScheme="blue"
                    onClick={(event) => handleEditModal(item, event)}
                  >
                    <FaEdit />
                  </Button>
                </Tooltip>
                <Tooltip label="Delete">
                  <Button
                    variant="solid"
                    colorScheme="red"
                    onClick={(event) => {
                      event.stopPropagation();
                      setServiceDataForDelete(item.id);
                      onOpen();
                    }}
                  >
                    <RiDeleteBin6Fill />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Card>
          ))}
        </div>
      )}

      <div className=" w-[95vw] absolute bottom-10">
        <Pagination pageCount={total_count} onPageChange={handlePageChange} />
      </div>
      <CustomModal
        modalTitle={"Delete"}
        modalText={`Are you sure to Delete Service ?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        actionFun={deleteComfirmFun}
        actionText={"Delete"}
      />
      <ServiceEditModal
        title={"Edit Service"}
        ref={serviceEditModalRef}
        fetchData={getServices}
      />
      <ServiceCreateModal
        title={"Add A New Service"}
        fetchData={getServices}
        ref={serviceCreateModalRef}
      />
      <ServiceDetailModal
        title={"Detail Service"}
        ref={serviceDetailModalRef}
        fetchData={getServices}
      />
    </Layout>
  );
};

export default AdminServices;
