import React from "react";
import Layout from "../../layout/Layout";
import DashboardCard from "../Custom/DashboardCard";
import { navItems } from "../../constants/constants";
import { Container, Text } from "@chakra-ui/react";

const AdminDashBoard = () => {
  return (
    <Layout>
      <div className=" pt-20 md:pt-10 ">
        <Text
          fontSize={"30px"}
          fontWeight={"bold"}
          // cursor={"pointer"}
          className=" pt-10 md:pt-0 ml-3 md:ml-0"
        >
          Dashboard
        </Text>
        <div className="flex flex-wrap justify-start">
          {navItems.map((item) => {
            return (
              <DashboardCard
                title={item.title}
                key={item.id}
                route={item.route}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashBoard;
