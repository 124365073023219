interface Config {
  hashTrigger: string;
  developmentUrl: string;
  jwtSecret: string;
}

export const config: Config = {
  hashTrigger: process.env.REACT_APP_HASH_CODE || "",
  developmentUrl: process.env.REACT_APP_DEVELOPMENT_URL || "",
  jwtSecret: process.env.REACT_APP_JWT_SECRET || "",
};
