import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./slices/globalSlice";
import gallerySlice from "./slices/gallerySlice";
import photosSlice from "./slices/photosSlice";
import adminSlice from "./slices/adminSlice";
import contactInfoSlice from "./slices/ContactSlice";
import serviceSlice from "./slices/serviceSlice";

// ...

export const store = configureStore({
  reducer: {
    global: globalSlice,
    gallery: gallerySlice,
    photo: photosSlice,
    admin: adminSlice,
    contact: contactInfoSlice,
    service: serviceSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
