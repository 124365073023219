import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { Image } from "@chakra-ui/react";
import { Link, scroller } from "react-scroll";
import { tabs } from "../Components/bars/PublicNavBar";

const Footer = () => {
  const handleFloatingButtonClick = () => {
    scroller.scrollTo("Home", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    // You can add any functionality here, such as navigating to a different section or opening a modal
  };
  return (
    <div className=" mt-auto">
      <div className="w-[100%] flex justify-center bg-[#0B4D39]">
        <div className=" container block lg:flex lg:pt-12">
          <div className=" w-[100%] lg:w-[27%] px-10 py-5">
            <div
              className=" text-white cursor-pointer"
              onClick={handleFloatingButtonClick}
            >
              <Image
                src="/royal_smart_tech.png"
                alt="Royal_Smart_Tech_Logo"
                width={100}
                height={100}
                className="rounded-[100%] mx-auto"
              />
              <h1 className=" text-[22px] lg:text-[25px] text-[#fff] dark:text-white tinos-bold leading-8 select-none text-center mt-5">
                Royal Smart Tech
              </h1>
            </div>
          </div>
          <div className=" w-[100%] lg:w-[36.5%] text-white lg:mb-3">
            <div className=" px-5 pt-5 pb-2">
              <h1 className=" font-bold text-[20px]">Address</h1>
            </div>
            <div className=" flex px-5 py-2">
              <FaMapMarkerAlt className="text-[18px] mr-5" />
              <div className=" w-[85%]">
                {" "}
                NO. 167,CORNER OF THAZIN STREET & HNIN PAN STREET, AUNG ZABU
                QUARTER, ZABUTHIRI TOWNSHIP, NAYPYITAW, MYANMAR.
              </div>
            </div>

            <a href="tel:(+95) 9790407994" className=" flex px-5 py-2">
              <FaPhoneAlt className="text-[18px] mr-5" />
              <div className="">
                {" "}
                <a
                  className="text-[15px] lg:text-[17px] hover:text-[#0490DD] transition-all duration-300"
                  href="tel:(+95) 9250816883"
                >
                  {" "}
                  +959250816883
                  {" , "}
                </a>
                <a
                  className="text-[15px] lg:text-[17px] hover:text-[#0490DD] transition-all duration-300"
                  href="tel:(+95) 9428921317"
                >
                  {" "}
                  +959428921317
                </a>
              </div>
            </a>
            <a href="mailto:contact@rstmyanmar.com" className=" flex px-5 py-2">
              <FaRegEnvelope className="text-[18px] mr-5" />
              <div className=" hover:text-[#0490DD] transition-all duration-300">
                {" "}
                contact@rstmyanmar.com
              </div>
            </a>
            <a
              href="https://www.facebook.com/RoyalSmartTechMM"
              className=" text-[15] lg:text-[17px]  flex px-5 py-2"
            >
              <FaFacebookSquare className="text-[18px]  mr-5 " />
              <div className=" hover:text-[#0490DD] transition-all duration-300">
                Royal Smart-Tech Myanmar
              </div>
            </a>
          </div>
          <div className=" hidden lg:block w-[100%] lg:w-[36.53%] text-white">
            <div className=" px-5 pt-5 pb-2">
              <h1 className=" font-bold text-[20px] text-center">Links</h1>
            </div>
            <ul className="text-sm font-medium w-[80%] flex flex-col mx-auto mb-8">
              {tabs.map((item) => {
                return (
                  <Link
                    to={item.route}
                    spy={true}
                    smooth={true}
                    key={item.id}
                    offset={-100}
                    duration={700}
                    delay={0}
                    // onSetActive={() => handleSetActive(item.name)}
                    // onSetActive={() => handleSetActive(item.name)}
                    className=" mr-2 cursor-pointer justify-center align-middle"
                  >
                    <li className="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600 hover:bg-[#75A595] text-center">
                      {item.name}
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* <div className=" w-[100%] bg-[#000] text-center py-5">
        <h1 className=" text-white">
          Copyright © 2024{" "}
          <a
            href="https://techarea.space/"
            className=" text-gray-300 font-semibold"
          >
            Tech Area
          </a>
        </h1>
      </div> */}
    </div>
  );
};

export default Footer;
