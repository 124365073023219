import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AdminDataType } from "../../types/AdminDataType";

interface AdminSliceState {
  isLoading: boolean;
  data: AdminDataType[];
  error: Error | null;
}

const initialState: AdminSliceState = {
  isLoading: false,
  data: [],
  error: null,
};

export const adminSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setAdminDatas: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading, setAdminDatas } = adminSlice.actions;

export default adminSlice.reducer;
