import { Navigate, Outlet } from "react-router-dom";

const PrivateRouter = () => {
  const access_token = localStorage.getItem("access_token");

  return access_token ? (
    <Outlet />
  ) : (
    <Navigate to={"/admin_dashboard_panel/login"} />
  );
};

export default PrivateRouter;
