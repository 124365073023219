import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "../App";
import PrivateRouter from "./PrivateRouter";
import AdminDashBoardLogin from "../Components/admin_dashboard_panel/AdminDashBoardLogin";
import Gallery from "../Components/admin_dashboard_panel/Gallery";
import AddPhotosDetail from "../Components/admin_dashboard_panel/AddPhotosDetails/AddPhotosDetail";
import AdminManagement from "../Components/admin_dashboard_panel/AdminManagement/AdminManagement";
import Home from "../PublicComponents/Home";
import About from "../PublicComponents/About";
import Services from "../PublicComponents/Services";
import Contact from "../PublicComponents/Contact";
import AdminContact from "../Components/admin_dashboard_panel/ContactUs/AdminContact";
import AdminServices from "../Components/admin_dashboard_panel/Services/AdminServices";
import GalleryDetails from "../PublicComponents/GalleryDetails/GalleryDetails";
import AdminDashBoard from "../Components/admin_dashboard_panel/AdminDashBoard";

/*export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/menus",
    element: <Menus />,
  },
  {
    path: "/menus/:menuId",
    element: <MenuDetails />,
  },
  {
    path: "/menu-categories",
    element: <MenuCategories />,
  },
  {
    path: "/addons",
    element: <Addons />,
  },
  {
    path: "/addon-categories",
    element: <AddonCategories />,
  },
  {
    path: "/setting",
    element: <Setting />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
]);
*/

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRouter />}>
          <Route
            path="/admin_dashboard_panel/dashboard"
            Component={AdminDashBoard}
          />
          <Route
            path="/admin_dashboard_panel"
            element={<Navigate to="/admin_dashboard_panel/dashboard" />}
          />
          <Route
            path="/admin_dashboard_panel/"
            element={<Navigate to="/admin_dashboard_panel/dashboard" />}
          />
          <Route path="/admin_dashboard_panel/gallery" Component={Gallery} />
          <Route
            path="/admin_dashboard_panel/admin"
            Component={AdminManagement}
          />

          <Route
            path="/admin_dashboard_panel/gallery/:id"
            Component={AddPhotosDetail}
          />
          <Route
            path="/admin_dashboard_panel/services"
            Component={AdminServices}
          />

          <Route
            path="/admin_dashboard_panel/contact"
            Component={AdminContact}
          />
        </Route>
        <Route
          path="/admin_dashboard_panel/login"
          Component={AdminDashBoardLogin}
        />
        <Route path="*" Component={App} />
        <Route path="/" Component={App} />
        <Route path="/gallery/:id" Component={GalleryDetails} />
        {/* <Route path="/about" Component={About} />
        <Route path="/gallery" Component={Gallery} />
        <Route path="/services" Component={Services} />
        <Route path="/contact" Component={Contact} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
