import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersImgSlide = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1400, // Tablet and smaller
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1200, // Tablet and smaller
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024, // Tablet and smaller
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768, // Mobile
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Extra small devices
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const images = [
    { src: "./assets/hp_Logo.png", alt: "HP", title: "HP" },
    { src: "./assets/cisco.png", alt: "CISCO", title: "CISCO" },
    { src: "./assets/dell.png", alt: "DELL", title: "DELL" },
    { src: "./assets/hikvision.png", alt: "HIKVISION", title: "HIKVISION" },
    { src: "./assets/dahua.png", alt: "Dahua", title: "Dahua" },
    { src: "./assets/a10.png", alt: "A10", title: "A10" },
    { src: "./assets/soteria.png", alt: "SOTERIA", title: "SOTERIA" },
    { src: "./assets/microsoft.png", alt: "MICROSOFT", title: "MICROSOFT" },
    { src: "./assets/f5.jpg", alt: "F5", title: "F5" },
    { src: "./assets/vanguard.jpg", alt: "Vanguard", title: "Vanguard" },
    { src: "./assets/paloalto.png", alt: "PALOALTO", title: "PALOALTO" },
  ];

  return (
    <div className="carousel-container bg-[#0B4D39] rounded-t-2xl overflow-hidden">
      <h2 className="text-white text-center text-[28px] lg:text-[32px] font-bold lg:mb-2">
        Our Partners
      </h2>
      <Slider {...settings} className="justify-center lg:pt-5 lg:pb-12">
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img
              src={image.src}
              alt={image.alt}
              className="object-fill w-[150px] h-[130px] md:w-[180px] md:h-[130px] pt-5 md:pt-0"
            />
            {/* <p>{image.title}</p> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersImgSlide;
