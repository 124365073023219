import { Box } from "@chakra-ui/react";
import { ReactComponentElement, useState } from "react";

const MessageTruncatedText = ({ text }: { text: string }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText = isExpanded
    ? text
    : text.split(" ").slice(0, 7).join(" ");

  return (
    <div>
      <Box
        className={`truncated-text ${isExpanded ? "expanded" : ""}`}
        lineHeight={4}
      >
        {truncatedText}
        {!isExpanded && text.split(" ").length > 10 && (
          <span
            onClick={toggleExpanded}
            style={{ cursor: "pointer", color: "#194148", fontSize: "12px" }}
            className=" hover:text-blue-600 ml-3"
          >
            {"  ... See More"}
          </span>
        )}
        {isExpanded && (
          <div
            onClick={toggleExpanded}
            style={{ cursor: "pointer", color: "#194148", fontSize: "12px" }}
            className=" hover:text-blue-600 mt-3"
          >
            {"  ....... less"}
          </div>
        )}
      </Box>
    </div>
  );
};

export default MessageTruncatedText;
