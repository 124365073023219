import React, { useEffect, useMemo, useState } from "react";
import usePagination from "../../../hooks/usePagination";
import { getToken } from "../../../lib/auth";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import {
  Box,
  Button,
  Flex,
  TableContainer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import TruncatedText from "../../Custom/TruncatedText";
import Layout from "../../../layout/Layout";
import { useAppSelector } from "../../../store/hooks";
import CustomTableWithPagination from "../../Custom/CustomTableWithPagination";
import { useDispatch } from "react-redux";
import { setContactInfoDatas } from "../../../store/slices/ContactSlice";
import { RiDeleteBin6Fill } from "react-icons/ri";
import MessageTruncatedText from "../../Custom/MessageTruncatedText";
import {
  setDeleteLoading,
  setFetchLoading,
  setTotal_count,
} from "../../../store/slices/globalSlice";
import CustomModal from "../../Custom/CustomModal";
import Loading from "../../Custom/Loading";

export interface ContactsInfoDataType {
  id?: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactsInfo = () => {
  const { data } = useAppSelector((state) => state.contact);
  const { total_count, isFetchLoading } = useAppSelector(
    (state) => state.global
  );
  // const [contactsInfo, setContactsInfo] = useState<ContactsInfoDataType[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onPaginationChange, pagination } = usePagination();
  const [deleteContactId, setDeleteContactId] = useState("");
  const access_token = getToken();
  const toast = useToast();
  const dispatch = useDispatch();

  const pageCount = total_count
    ? Math.ceil(total_count / pagination.pageSize)
    : 0;

  const toastFun = (
    condition: string,
    description: string,
    statusInd: "error" | "success"
  ) => {
    toast({
      position: "top-right",
      title: condition,
      description: description,
      status: statusInd,
      duration: 3000,
      isClosable: true,
    });
  };

  const fetchContacts = async () => {
    dispatch(setFetchLoading(true));
    const obj = {
      page: pagination.pageIndex + 1,
      perPage: pagination.pageSize,
    };
    const response = await fetch(
      `${process.env.REACT_APP_DEVELOPMENT_URL}/contactus?page=${obj.page}&perpage=${obj.perPage}&read=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        // body: JSON.stringify(accessToken),
      }
    );
    const data = await response.json();
    // console.log("data :: ", data);
    dispatch(setContactInfoDatas(data?.contacts));
    dispatch(setTotal_count(data?.pagination.totalCount));
    dispatch(setFetchLoading(false));

    // const unseenCount = await getUnseenCount(data.contacts);
    // setUnreadCount(unseenCount);
    // setContactsInfo(data.contacts);
    // setTotalCount(data.pagination.totalCount);
  };

  // console.log("contactsInfo :: ", contactsInfo);

  useEffect(() => {
    fetchContacts();
  }, [pagination]);

  const deleteComfirmFun = async () => {
    dispatch(setDeleteLoading(true));
    if (deleteContactId) {
      const response = await fetch(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/contactus/${deleteContactId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      const data = await response.json();

      if (data.code === 200) toastFun("Success", data.message, "success");
      if (data.code === 400 || data.code === 404)
        toastFun("Error", data.message, "error");
      dispatch(setDeleteLoading(false));
      onClose();
      fetchContacts();
    }
  };

  const handleDelete = (id: string) => {
    setDeleteContactId(id);
    onOpen();
  };

  const columns = useMemo<ColumnDef<ContactsInfoDataType, React.ReactNode>[]>(
    () => [
      {
        header: "No",
        accessorFn: (_row, index) => index + 1,
        id: "serialNumber",
      },
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Email",
        cell: ({ row }: CellContext<ContactsInfoDataType, React.ReactNode>) => (
          <Text>{row.original.email ? row.original.email : " - "}</Text>
        ),
      },
      {
        header: "Phone",
        cell: ({ row }: CellContext<ContactsInfoDataType, React.ReactNode>) => (
          <Text>{row.original.phone ? row.original.phone : " - "}</Text>
        ),
      },
      {
        header: "Message",
        cell: ({ row }: CellContext<ContactsInfoDataType, React.ReactNode>) => (
          <Box flexWrap={"wrap"} width={"450px"} whiteSpace={"pre-line"}>
            <MessageTruncatedText
              text={row.original.message ? row.original.message : " - "}
            />
            {/* <Text>{row.original.message ? row.original.message : " - "}</Text> */}
          </Box>
        ),
      },
      {
        header: "Action",
        id: "actions",
        cell: ({ row }: CellContext<ContactsInfoDataType, React.ReactNode>) => (
          <Flex gap={3}>
            <Button
              colorScheme="red"
              onClick={() => {
                row.original && handleDelete(row.original?.id as string);
              }}
              // leftIcon={<RiDeleteBin6Fill />}
            >
              <RiDeleteBin6Fill />
              {/* Delete */}
            </Button>
          </Flex>
        ),
      },
    ],
    []
  );

  return (
    <Layout>
      <Box pt={{ base: "20", md: "10" }}>
        <Text
          fontSize={"30px"}
          fontWeight={"bold"}
          // cursor={"pointer"}
          className=" ml-3 md:ml-0"
        >
          Messages
        </Text>
        {isFetchLoading ? (
          <div className=" h-[55vh] lg:h-[70vh]">
            <Loading />
          </div>
        ) : (
          <TableContainer>
            <CustomTableWithPagination
              data={data}
              columns={columns}
              pagination={pagination}
              onPaginationChange={onPaginationChange}
              pageCount={pageCount}
            />
          </TableContainer>
        )}
      </Box>
      <CustomModal
        modalTitle={"Delete"}
        modalText={`Are you sure to Delete Contact Message ?`}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        actionFun={deleteComfirmFun}
        actionText={"Delete"}
      />
      {/* <h1>hello</h1> */}
    </Layout>
  );
};

export default ContactsInfo;
