import { Icon } from "@iconify/react";
import { RotatingLines, Vortex } from "react-loader-spinner";

interface PropsType {
  fullScreen?: boolean;
}

const Loading = ({ fullScreen = false }: PropsType) => {
  return (
    <div
      className={`w-full flex justify-center items-center ${
        fullScreen ? "h-screen" : "h-full"
      }`}
    >
      {/* <Icon
        icon="mingcute:loading-line"
        className="text-4xl animate-spin text-primary-500"
      /> */}
      <RotatingLines
        visible={true}
        width="80"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        strokeColor="#0B4D39"
      />
    </div>
  );
};

export default Loading;
